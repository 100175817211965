<template>
  <div class="d-flex flex-wrap w-100 justify-content-center mb-2">
    <div
      v-for="(header, index) in headerList"
      :key="index"
      class="d-flex justify-content-evenly align-items-center my-1"
    >
      <div
        v-if="
          (currentStep === 0 || (currentStep > 0 && currentStep < 9)) &&
          index === 0
        "
      >
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline">
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0">
          {{ header.label }}
        </span>
      </div>

      <div
        v-else-if="
          ((currentStep > 0 && currentStep < 4) ||
            (currentStep > 3 && currentStep < 9)) &&
          index === 1
        "
      >
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline">
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0">
          {{ header.label }}
        </span>
      </div>

      <div
        v-else-if="
          (currentStep === 4 || (currentStep > 4 && currentStep < 9)) &&
          index === 2
        "
      >
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline">
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0">
          {{ header.label }}
        </span>
      </div>

      <div
        v-else-if="
          ((currentStep >= 5 && currentStep <= 7) ||
            (currentStep > 7 && currentStep < 9)) &&
          index === 3
        "
      >
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline">
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0">
          {{ header.label }}
        </span>
      </div>

      <div v-else-if="currentStep === 8 && index === 4">
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline">
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0">
          {{ header.label }}
        </span>
      </div>

      <div v-else>
        <feather-icon
          v-if="index !== 0"
          icon="ChevronRightIcon"
          size="28"
          class="mr-1"
        />
        <div
          class="rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline bg-lighten-5"
        >
          <feather-icon :icon="header.icon" size="16" />
        </div>
        <span class="h1 font-small-3 m-0 text-darken-5">
          {{ header.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbHeader",
  components: {},
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    headerList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
