var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap w-100 justify-content-center mb-2"},_vm._l((_vm.headerList),function(header,index){return _c('div',{key:index,staticClass:"d-flex justify-content-evenly align-items-center my-1"},[(
        (_vm.currentStep === 0 || (_vm.currentStep > 0 && _vm.currentStep < 9)) &&
        index === 0
      )?_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0"},[_vm._v(" "+_vm._s(header.label)+" ")])],1):(
        ((_vm.currentStep > 0 && _vm.currentStep < 4) ||
          (_vm.currentStep > 3 && _vm.currentStep < 9)) &&
        index === 1
      )?_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0"},[_vm._v(" "+_vm._s(header.label)+" ")])],1):(
        (_vm.currentStep === 4 || (_vm.currentStep > 4 && _vm.currentStep < 9)) &&
        index === 2
      )?_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0"},[_vm._v(" "+_vm._s(header.label)+" ")])],1):(
        ((_vm.currentStep >= 5 && _vm.currentStep <= 7) ||
          (_vm.currentStep > 7 && _vm.currentStep < 9)) &&
        index === 3
      )?_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0"},[_vm._v(" "+_vm._s(header.label)+" ")])],1):(_vm.currentStep === 8 && index === 4)?_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0"},[_vm._v(" "+_vm._s(header.label)+" ")])],1):_c('div',[(index !== 0)?_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronRightIcon","size":"28"}}):_vm._e(),_c('div',{staticClass:"rounded bg-primary text-white p-1 mr-1 d-none d-sm-inline bg-lighten-5"},[_c('feather-icon',{attrs:{"icon":header.icon,"size":"16"}})],1),_c('span',{staticClass:"h1 font-small-3 m-0 text-darken-5"},[_vm._v(" "+_vm._s(header.label)+" ")])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }